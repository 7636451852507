<template>
  <div>
    <Navbar />
    <!-- ! Main Begin -->
    <main id="main" class="main">
      <section class="home-banner-section inner-banner cover-bg" id="banner">
        <div class="banner-caption">
          <div class="banner-caption-content text-white">
            <div
              class="
                banner-caption-text
                d-flex
                w-100
                align-items-center
                flex-warp
                h-100
                py-5
              "
            >
              <div class="container">
                <div class="banner-text">
                  <h1 class="title-border">Privacy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="curved-section-main">
        <!-- ! Section start -->
        <section class="section curved-section no-min-height">
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding">
            <h2>Privacy notice</h2>
            <p class="text-secondary">Last updated October 20, 2021</p>
            <p>
              Thank you for choosing to be part of our community at JAB
              Companies, Inc. ("Company," "we," "us," or "our"). We are
              committed to protecting your personal information and your right
              to privacy. If you have any questions or concerns about this
              privacy notice or our practices with regard to your personal
              information, please contact us at
              <a href="mailto:daveb@jabcomp.com">daveb@jabcomp.com</a>.
            </p>
            <h6>
              This privacy notice describes how we might use your information if
              you:
            </h6>
            <ul>
              <li>
                Visit our website at
                <a href="http://www.vaxtraxglobal.com">www.vaxtraxglobal.com</a>
              </li>
              <li>Download and use our mobile application — VAXTRAX GLOBAL</li>
              <li>
                Engage with us in other related ways ― including any sales,
                marketing, or events
              </li>
            </ul>
            <h6>In this privacy notice, if we refer to:</h6>
            <ul>
              <li>
                <strong>"Website," </strong>we are referring to any website of
                ours that references or links to this policy
              </li>
              <li>
                <strong>"App,"</strong> we are referring to any application of
                ours that references or links to this policy, including any
                listed above
              </li>
              <li>
                <strong>"Services,"</strong> we are referring to our Website,
                App, and other related services, including any sales, marketing,
                or events
              </li>
            </ul>
            <p>
              The purpose of this privacy notice is to explain to you in the
              clearest way possible what information we collect, how we use it,
              and what rights you have in relation to it. If there are any terms
              in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
            </p>
            <p>
              <strong
                >Please read this privacy notice carefully, as it will help you
                understand what we do with the information that we
                collect.</strong
              >
            </p>
            <h3>Table of contents</h3>
            <ol>
              <li>
                <a href="#tb-1" class="scrollto">INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="#tb-2" class="scrollto"
                  >HOW DO WE USE YOUR INFORMATION?</a
                >
              </li>
              <li>
                <a href="#tb-3" class="scrollto"
                  >WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a
                >
              </li>
              <li>
                <a href="#tb-4" class="scrollto"
                  >DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a
                >
              </li>
              <li>
                <a href="#tb-5" class="scrollto"
                  >HOW LONG DO WE KEEP YOUR INFORMATION?</a
                >
              </li>
              <li>
                <a href="#tb-6" class="scrollto"
                  >HOW DO WE KEEP YOUR INFORMATION SAFE?</a
                >
              </li>
              <li>
                <a href="#tb-7" class="scrollto"
                  >WHAT ARE YOUR PRIVACY RIGHTS?</a
                >
              </li>
              <li>
                <a href="#tb-8" class="scrollto"
                  >CONTROLS FOR DO-NOT-TRACK FEATURES</a
                >
              </li>
              <li>
                <a href="#tb-9" class="scrollto"
                  >DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a
                >
              </li>
              <li>
                <a href="#tb-10" class="scrollto"
                  >DO WE MAKE UPDATES TO THIS NOTICE?</a
                >
              </li>
              <li>
                <a href="#tb-11" class="scrollto"
                  >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
                >
              </li>
              <li>
                <a href="#tb-12" class="scrollto"
                  >HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                  YOU?</a
                >
              </li>
            </ol>
            <div id="tb-1" class="mb-4">
              <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
              <h6>Personal information you disclose to us</h6>
              <p>
                <strong><i>In Short:</i></strong> We collect personal
                information that you provide to us.
              </p>
              <p>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services (such as by
                posting messages in our online forums or entering competitions,
                contests or giveaways) or otherwise when you contact us.
              </p>
              <p>
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make and the products and features you use.
              </p>
              <h6>
                The personal information we collect may include the following:
              </h6>
              <p>
                <strong>Personal Information Provided by You.</strong> We
                collect names; phone numbers; email addresses; usernames;
                passwords; mailing addresses; contact or authentication data;
                billing addresses; debit/credit card numbers; contact
                preferences; date of birth; drivers's license; passport; and
                other similar information.
              </p>
              <p>
                Payment Data. We may collect data necessary to process your
                payment if you make purchases, such as your payment instrument
                number (such as a credit card number), and the security code
                associated with your payment instrument. All payment data is
                stored by Braintree Payments. You may find their privacy notice
                link(s) here:
                <a
                  href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
                  target="_blank"
                >
                  https://www.paypal.com/us/webapps/mpp/ua/privacy-full.
                </a>
              </p>
              <p>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <h6>Information collected through our App</h6>
              <p>
                <i>In Short:</i> We collect information regarding your
                geolocation, push notifications, when you use our App.
              </p>
              <p>
                <strong>
                  you use our App, we also collect the following
                  information:</strong
                >
              </p>
              <ul>
                <li>
                  Geolocation Information. We may request access or permission
                  to and track location-based information from your mobile
                  device, either continuously or while you are using our App, to
                  provide certain location-based services. If you wish to change
                  our access or permissions, you may do so in your device's
                  settings.
                </li>
                <li>
                  Push Notifications. We may request to send you push
                  notifications regarding your account or certain features of
                  the App. If you wish to opt-out from receiving these types of
                  communications, you may turn them off in your device's
                  settings.
                </li>
              </ul>
              <p>
                This information is primarily needed to maintain the security
                and operation of our App, for troubleshooting and for our
                internal analytics and reporting purposes.
              </p>
            </div>
            <div id="tb-2" class="mb-4">
              <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
              <p>
                <strong><i>In Short:</i></strong> We process your information
                for purposes based on legitimate business interests, the
                fulfillment of our contract with you, compliance with our legal
                obligations, and/or your consent.
              </p>
              <p>
                We use personal information collected via our Services for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </p>
              <h6>We use the information we collect or receive:</h6>
              <ul>
                <li>
                  <strong
                    >To facilitate account creation and logon process.</strong
                  >
                  If you choose to link your account with us to a third-party
                  account (such as your Google or Facebook account), we use the
                  information you allowed us to collect from those third parties
                  to facilitate account creation and logon process for the
                  performance of the contract.
                </li>
                <li>
                  <strong>To post testimonials.</strong> We post testimonials on
                  our Services that may contain personal information. Prior to
                  posting a testimonial, we will obtain your consent to use your
                  name and the content of the testimonial. If you wish to
                  update, or delete your testimonial, please contact us at
                  privacy@vaxtraxglobal.com and be sure to include your name,
                  testimonial location, and contact information.
                </li>
                <li>
                  <strong>Request feedback.</strong>We may use your information
                  to request feedback and to contact you about your use of our
                  Services.
                </li>
                <li>
                  <strong>To enable user-to-user communications.</strong> We may
                  use your information in order to enable user-to-user
                  communications with each user's consent.
                </li>
                <li>
                  <strong>To manage user accounts.</strong> We may use your
                  information for the purposes of managing our account and
                  keeping it in working order.
                </li>
                <li>
                  <strong>To send administrative information to you.</strong>We
                  may use your personal information to send you product, service
                  and new feature information and/or information about changes
                  to our terms, conditions, and policies.
                </li>
                <li>
                  <strong>To protect our Services.</strong>We may use your
                  information as part of our efforts to keep our Services safe
                  and secure (for example, for fraud monitoring and prevention).
                </li>
                <li>
                  <strong
                    >To enforce our terms, conditions and policies for business
                    purposes, to comply with legal and regulatory requirements
                    or in connection with our contract.</strong
                  >
                </li>
                <li>
                  <strong
                    >To respond to legal requests and prevent harm.</strong
                  >
                  If we receive a subpoena or other legal request, we may need
                  to inspect the data we hold to determine how to respond.
                </li>
                <li>
                  <strong>Fulfill and manage your orders.</strong> We may use
                  your information to fulfill and manage your orders, payments,
                  returns, and exchanges made through the Services.
                </li>
                <li>
                  <strong>Administer prize draws and competitions.</strong>We
                  may use your information to administer prize draws and
                  competitions when you elect to participate in our
                  competitions.
                </li>
                <li>
                  <strong
                    >To deliver and facilitate delivery of services to the
                    user.</strong
                  >
                  We may use your information to provide you with the requested
                  service.
                </li>
                <li>
                  <strong
                    >To respond to user inquiries/offer support to
                    users.</strong
                  >
                  We may use your information to respond to your inquiries and
                  solve any potential issues you might have with the use of our
                  Services.
                </li>
              </ul>
            </div>
            <div id="tb-3" class="mb-4">
              <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
              <p>
                <strong><i>In Short:</i></strong> We only share information with
                your consent, to comply with laws, to provide you with services,
                to protect your rights, or to fulfill business obligations.
              </p>
              <h6>
                We may process or share your data that we hold based on the
                following legal basis:
              </h6>
              <ul>
                <li>
                  <strong>Consent:</strong> We may process your data if you have
                  given us specific consent to use your personal information for
                  a specific purpose.
                </li>
                <li>
                  <strong>Legitimate Interests:</strong> We may process your
                  data when it is reasonably necessary to achieve our legitimate
                  business interests.
                </li>
                <li>
                  <strong>Performance of a Contract:</strong>Where we have
                  entered into a contract with you, we may process your personal
                  information to fulfill the terms of our contract.
                </li>
                <li>
                  <strong>Legal Obligations:</strong> We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to
                  public authorities to meet national security or law
                  enforcement requirements).
                </li>
                <li>
                  <strong>Vital Interests: </strong> We may disclose your
                  information where we believe it is necessary to investigate,
                  prevent, or take action regarding potential violations of our
                  policies, suspected fraud, situations involving potential
                  threats to the safety of any person and illegal activities, or
                  as evidence in litigation in which we are involved.
                </li>
              </ul>
              <h6>
                <strong
                  >More specifically, we may need to process your data or share
                  your personal information in the following situations:</strong
                >
              </h6>
              <ul>
                <li>
                  <strong>Business Transfers.</strong> We may share or transfer
                  your information in connection with, or during negotiations
                  of, any merger, sale of company assets, financing, or
                  acquisition of all or a portion of our business to another
                  company.
                </li>
                <li>
                  <strong>Google Maps Platform APIs.</strong> We may share your
                  information with certain Google Maps Platform APIs (e.g.,
                  Google Maps API, Place API). To find out more about Google’s
                  Privacy Policy, please refer to this
                  <a
                    href="https://vaxtraxglobal-front.web.app/#/privacy"
                    target="_blank"
                    >link</a
                  >.
                </li>
              </ul>
            </div>
            <div id="tb-4" class="mb-4">
              <h4>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
              <p>
                <strong><i>In Short:</i></strong> We may use cookies and other
                tracking technologies to collect and store your information.
              </p>
              <p>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
            </div>
            <div id="tb-5" class="mb-4">
              <h4>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
              <p>
                <strong><i>In Short:</i></strong> We keep your information for
                as long as necessary to fulfill the purposes outlined in this
                privacy notice unless otherwise required by law.
              </p>
              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </p>
              <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
            <div id="tb-6" class="mb-4">
              <h4>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
              <p>
                <strong><i>In Short:</i></strong> We aim to protect your
                personal information through a system of organizational and
                technical security measures.
              </p>
              <p>
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Services is at your own risk. You should only access the
                Services within a secure environment.
              </p>
            </div>
            <div id="tb-7" class="mb-4">
              <h4>7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
              <p>
                <strong><i>In Short:</i></strong> In some regions, such as the
                European Economic Area (EEA) and United Kingdom (UK), you have
                rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your
                account at any time.
              </p>
              <p>
                In some regions (like the EEA and UK), you have certain rights
                under applicable data protection laws. These may include the
                right (i) to request access and obtain a copy of your personal
                information, (ii) to request rectification or erasure; (iii) to
                restrict the processing of your personal information; and (iv)
                if applicable, to data portability. In certain circumstances,
                you may also have the right to object to the processing of your
                personal information. To make such a request, please use the
                contact details provided below. We will consider and act upon
                any request in accordance with applicable data protection laws.
              </p>
              <p>
                If we are relying on your consent to process your personal
                information, you have the right to withdraw your consent at any
                time. Please note however that this will not affect the
                lawfulness of the processing before its withdrawal, nor will it
                affect the processing of your personal information conducted in
                reliance on lawful processing grounds other than consent.
              </p>
              <p>
                If you are a resident in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your local data protection supervisory
                authority. You can find their contact details here:
                <a
                  href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  target="_blank"
                  >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
                >
                .
              </p>
              <p>
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:
                <a
                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  target="_blank"
                  >https://www.edoeb.admin.ch/edoeb/en/home.html</a
                >.
              </p>
              <h6>Account Information</h6>
              <p>
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>
              <ul>
                <li>
                  Log in to your account settings and update your user account.
                </li>
              </ul>
              <p>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                applicable legal requirements.
              </p>
              <p>
                <strong> Cookies and similar technologies:</strong> Most Web
                browsers are set to accept cookies by default. If you prefer,
                you can usually choose to set your browser to remove cookies and
                to reject cookies. If you choose to remove cookies or reject
                cookies, this could affect certain features or services of our
                Services. To opt-out of interest-based advertising by
                advertisers on our Services visit
                <a href="http://www.aboutads.info/choices/" target="_blank">
                  http://www.aboutads.info/choices/</a
                >.
              </p>
              <p>
                <strong>Opting out of email marketing:</strong> You can
                unsubscribe from our marketing email list at any time by
                clicking on the unsubscribe link in the emails that we send or
                by contacting us using the details provided below. You will then
                be removed from the marketing email list — however, we may still
                communicate with you, for example to send you service-related
                emails that are necessary for the administration and use of your
                account, to respond to service requests, or for other
                non-marketing purposes. To otherwise opt-out, you may:
              </p>
              <ul>
                <li>
                  Access your account settings and update your preferences.
                </li>
              </ul>
            </div>
            <div id="tb-8" class="mb-4">
              <h4>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
            </div>
            <div id="tb-9" class="mb-4">
              <h4>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
              <p>
                <strong><i>In Short:</i></strong> Yes, if you are a resident of
                California, you are granted specific rights regarding access to
                your personal information.
              </p>
              <p>
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </p>
              <p>
                If you are under 18 years of age, reside in California, and have
                a registered account with a Service, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below, and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).
              </p>
              <h5>CCPA Privacy Notice</h5>
              <h6>
                The California Code of Regulations defines a "resident" as:
              </h6>
              <ol>
                <li>
                  every individual who is in the State of California for other
                  than a temporary or transitory purpose and
                </li>
                <li>
                  every individual who is domiciled in the State of California
                  who is outside the State of California for a temporary or
                  transitory purpose
                </li>
              </ol>
              <p>All other individuals are defined as "non-residents."</p>
              <p>
                If this definition of "resident" applies to you, we must adhere
                to certain rights and obligations regarding your personal
                information.
              </p>
              <h6>What categories of personal information do we collect?</h6>
              <p>
                We have collected the following categories of personal
                information in the past twelve (12) months:
              </p>
              <div class="table-responsive">
                <table
                  class="
                    table
                    b-table
                    text-center
                    table-striped table-hover
                    align-middle
                  "
                  role="table"
                >
                  <thead role="rowgroup" class="table-dark">
                    <tr>
                      <th class="text-start">Category</th>
                      <th class="text-start">Examples</th>
                      <th>Collected</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-start">A. Identifiers</td>
                      <td class="text-start">
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address and account name
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        B. Personal information categories listed in the
                        California Customer Records statute
                      </td>
                      <td class="text-start">
                        Name, contact information, education, employment,
                        employment history and financial information
                      </td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        C. Protected classification characteristics under
                        California or federal law
                      </td>
                      <td class="text-start">Gender and date of birth</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">D. Commercial information</td>
                      <td class="text-start">
                        Transaction information, purchase history, financial
                        details and payment information
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">E. Biometric information</td>
                      <td class="text-start">Fingerprints and voiceprints</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        F. Internet or other similar network activity
                      </td>
                      <td class="text-start">
                        Browsing history, search history, online behavior,
                        interest data, and interactions with our and other
                        websites, applications, systems and advertisements
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">G. Geolocation data</td>
                      <td class="text-start">Device location</td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        H. Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </td>
                      <td class="text-start">
                        Images and audio, video or call recordings created in
                        connection with our business activities
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        I. Professional or employment-related information
                      </td>
                      <td class="text-start">
                        Business contact details in order to provide you our
                        services at a business level, job title as well as work
                        history and professional qualifications if you apply for
                        a job with us
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">J. Education Information</td>
                      <td class="text-start">
                        Student records and directory information
                      </td>
                      <td>NO</td>
                    </tr>
                    <tr>
                      <td class="text-start">
                        K. Inferences drawn from other personal information
                      </td>
                      <td class="text-start">
                        Inferences drawn from any of the collected personal
                        information listed above to create a profile or summary
                        about, for example, an individual’s preferences and
                        characteristics
                      </td>
                      <td>NO</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6>
                We may also collect other personal information outside of these
                categories instances where you interact with us in-person,
                online, or by phone or mail in the context of:
              </h6>
              <ul>
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </li>
              </ul>
              <h6>How do we use and share your personal information?</h6>
              <p>
                More information about our data collection and sharing practices
                can be found in this privacy notice.
              </p>
              <p>
                You may contact us by email at support @vaxtraxglobal.com, or by
                referring to the contact details at the bottom of this document.
              </p>
              <p>
                If you are using an authorized agent to exercise your right to
                opt-out we may deny a request if the authorized agent does not
                submit proof that they have been validly authorized to act on
                your behalf.
              </p>
              <h6>Will your information be shared with anyone else?</h6>
              <p>
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Each service provider is a for-profit entity
                that processes the information on our behalf.
              </p>
              <p>
                We may use your personal information for our own business
                purposes, such as for undertaking internal research for
                technological development and demonstration. This is not
                considered to be "selling" of your personal data.
              </p>
              <p>
                AB Companies, Inc. has not disclosed or sold any personal
                information to third parties for a business or commercial
                purpose in the preceding 12 months. JAB Companies, Inc. will not
                sell personal information in the future belonging to website
                visitors, users and other consumers.
              </p>
              <h6>Your rights with respect to your personal data</h6>
              <p>
                <u>Right to request deletion of the data - Request to delete</u>
              </p>
              <p>
                You can ask for the deletion of your personal information. If
                you ask us to delete your personal information, we will respect
                your request and delete your personal information, subject to
                certain exceptions provided by law, such as (but not limited to)
                the exercise by another consumer of his or her right to free
                speech, our compliance requirements resulting from a legal
                obligation or any processing that may be required to protect
                against illegal activities.
              </p>
              <p><u>Right to be informed - Request to know</u></p>
              <p>Depending on the circumstances, you have a right to know:</p>
              <ul>
                <li>whether we collect and use your personal information;</li>
                <li>the categories of personal information that we collect;</li>
                <li>
                  the purposes for which the collected personal information is
                  used;
                </li>
                <li>
                  whether we sell your personal information to third parties;
                </li>
                <li>
                  the categories of personal information that we sold or
                  disclosed for a business purpose;
                </li>
                <li>
                  the categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose; and
                </li>
                <li>
                  the business or commercial purpose for collecting or selling
                  personal information.
                </li>
              </ul>
              <p>
                In accordance with applicable law, we are not obligated to
                provide or delete consumer information that is de-identified in
                response to a consumer request or to re-identify individual data
                to verify a consumer request.
              </p>
              <p>
                <u
                  >Right to Non-Discrimination for the Exercise of a Consumer’s
                  Privacy Rights</u
                >
              </p>
              <p>
                We will not discriminate against you if you exercise your
                privacy rights.
              </p>
              <p><u>Verification process</u></p>
              <p>
                Upon receiving your request, we will need to verify your
                identity to determine you are the same person about whom we have
                the information in our system. These verification efforts
                require us to ask you to provide information so that we can
                match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may
                ask you to provide certain information so that we can match the
                information you provide with the information we already have on
                file, or we may contact you through a communication method (e.g.
                phone or email) that you have previously provided to us. We may
                also use other verification methods as the circumstances
                dictate.
              </p>
              <p>
                We will only use personal information provided in your request
                to verify your identity or authority to make the request. To the
                extent possible, we will avoid requesting additional information
                from you for the purposes of verification. If, however, we
                cannot verify your identity from the information already
                maintained by us, we may request that you provide additional
                information for the purposes of verifying your identity, and for
                security or fraud-prevention purposes. We will delete such
                additionally provided information as soon as we finish verifying
                you.
              </p>
              <p><u>Other privacy rights</u></p>
              <ul>
                <li>you may object to the processing of your personal data</li>
                <li>
                  you may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the data
                </li>
                <li>
                  you can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </li>
                <li>
                  you may request to opt-out from future selling of your
                  personal information to third parties. Upon receiving a
                  request to opt-out, we will act upon the request as soon as
                  feasibly possible, but no later than 15 days from the date of
                  the request submission.
                </li>
              </ul>
              <p>
                To exercise these rights, you can contact us by email at support
                @vaxtraxglobal.com, or by referring to the contact details at
                the bottom of this document. If you have a complaint about how
                we handle your data, we would like to hear from you.
              </p>
            </div>
            <div id="tb-10" class="mb-4">
              <h4>10. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
              <p>
                <strong><i>In Short:</i></strong> Yes, we will update this
                notice as necessary to stay compliant with relevant laws.
              </p>
              <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
            </div>
            <div id="tb-11" class="mb-4">
              <h4>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
              <p>
                If you have questions or comments about this notice, you may
                email us at privacy@vaxtraxglobal.com or by post to:
              </p>
              <address>
                JAB Companies, Inc.<br />
                1405 Silver Lake Rd NW, New Brighton, MN 55112, USA<br />
                New Brighton, MN 55112<br />
                United States
              </address>
            </div>
            <div id="tb-12" class="mb-4">
              <h4>
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h4>
              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please submit a request form by clicking
                <a
                  href="https://app.termly.io/notify/4c62edb0-8586-4d27-9c33-9dc1bd7782b8"
                  target="_blank"
                  >here</a
                >.
              </p>
            </div>
          </div>
        </section>
        <!-- ! Section end -->
      </div>
      <!-- ! Section start -->
      <section
        id="lets_talk"
        class="section curved-section lets-talk-section cover-bg text-white"
        style="
          background-image: url(../../assets/images/contact-blockimage.jpg);
        "
      >
        <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
        <div class="container section-container-padding">
          <div class="section-top-bar d-flex">
            <div class="section-top-bar-container">
              <h2 class="section-title title-white">Let's <span>talk</span></h2>
            </div>
          </div>
          <div class="contact-box">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form h-100"
            >
              <div class="row">
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div class="validate"></div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                ></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-orange" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <!-- ! Section end -->
    </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>

import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";
td{
  border: 1px solid rgb(218, 213, 213);
}
.main {
font-weight: 500;
font-family: "Raleway";
}
#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#banner {
  background-image: url("../../assets/images/inneer-banner.jpg") !important;
}
</style>
